import iconWallet from "../../images/icons/styled/first/Wallet.svg";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconSplitPayment from "../../images/icons/styled/first/SplitPayment.svg";
import iconShape from "../../images/icons/styled/second/SplitPaymentsBeetweenPeople.svg";
import iconSettings from "../../images/icons/styled/first/Settings.svg";
import iconCalm from "../../images/icons/styled/first/Calm.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconBuilding from "../../images/icons/styled/second/Building.svg";
import links from "../../utils/links";
import iconCreateNew from "../../images/icons/styled/first/CreateNew.svg";

export default {
    "seo": {
        "title": "Bank 131 | Online Payments and Direct Mass Payouts to Russia",
        "description": "Bank 131 – Russia’s newest digital bank. A compliant payment solution for Russia, mass payouts, payouts to the self-employed, online payments, and banking as a service"
    },
    "pageHeaderBlock": {
        "title": "Russia’s newest bank </br> for the digital economy",
        "text": "API-based online payments, mass payouts and banking as a service for international online businesses in Russia. B2C and C2B payments – your rules, our technology."
    },

    "submitApplicationBlock": {
        "textSubmit": "Sign up",
        "textSubmitBottom": "Get in touch",
    },

    "infoCardsBlock": {
        "cardsTitle": "Solutions",
        "cards": [
            {
                title: 'Send payouts',
                text: 'Pay individuals and self-employed people in Russia by sending money to Russian bank cards Visa, Mastercard, Mir, bank accounts, and e-wallets.',
                icon: iconWallet,
                description: '',
                theme: 'cardFirst',
                fullLink: {
                    to: '/en/products/payouts'
                }
            },
            {
                title: 'Accept payments',
                text: 'Accept online payments with bank cards Visa, Mastercard, Mir in our widget or via subscription. Get money sent to your preferred bank account worldwide.',
                icon: iconCard,
                description: '',
                theme: 'cardSecond',
                fullLink: {
                    to: '/en/products/payments'
                }
            },
            {
                "title": "Open corporate bank account",
                "text": "Open a current account to accept payments and send payouts. Track your transactions in online bank.",
                "icon": iconBuilding,
                "description": "",
                "theme": "cardSecond",
                "fullLink": {
                    "to": "/en/products/account"
                }
            },
            {
                title: "Split payment for marketplaces",
                text: 'A solution that lets marketplaces and other aggregators split accepted online payments between sellers ' +
                    'and other partners. Accept payments from users and settle with several recipients at once.',
                icon: iconSplitPayment,
                description: '',
                theme: 'cardFirst',
                fullLink: {
                    to: '/en/products/marketplaces'
                }
            },
            {
                title: 'Split payment for C2C services',
                text: 'A solution enabling c2c platforms to split accepted online payments between users and the platform. ' +
                    'Allows users to settle directly and receive only a commission fee.',
                icon: iconShape,
                description: '',
                theme: 'cardSecond',
                fullLink: {
                    to: '/en/products/c2c'
                }
            },
        ]
    },
    "infoCardsBlockWhyUs": {
        "cardsTitle": "Why us",
        "cards": [
            {
                title: 'Scale up your business',
                text: 'Using the latest payment technology can help you change your business model, find new monetization ' +
                    'sources, and cut costs. We offer legal, compliant solutions for settling with outsourcers, freelancers, ' +
                    'couriers, and sellers.',
                icon: iconSettings,
                theme: 'withoutBackground'
            },
            {
                title: 'Developer-friendly',
                text: 'Modern API and SDK, detailed documentation, and a straightforward testing environment. ' +
                    'Everything you need for payments, payouts and settlement between people and companies online.',
                icon: iconLightning,
                link: {
                    href: links.en.api,
                    text: 'Look at our documentation'
                },
                theme: 'withoutBackground'
            },
            {
                title: "Compliance",
                text: 'We’re fully integrated with the Russian Federal Tax Service, so we can verify tax statuses, register ' +
                    'people as self-employed, issue fiscal receipts and pay taxes. Full compliance with local payment ' +
                    'regulations, tax rules and AML/CFT.',
                icon: iconCalm,
                theme: 'withoutBackground'
            },
            {
                title: 'Reliability',
                text: 'We work officially – with a banking license from the Central Bank of Russia.We’re transparent about reporting, directly supervised by the Central Bank of Russia, and conform to international requirements and the highest level of PCI-DSS compliance.',
                icon: iconShield,
                theme: 'withoutBackground'
            }
        ]
    }
}