import iconWallet from "../../images/icons/styled/first/Wallet.svg";
import iconCard from "../../images/icons/styled/second/Card.svg";
import iconSplitPayment from "../../images/icons/styled/first/SplitPayment.svg";
import iconShape from "../../images/icons/styled/second/SplitPaymentsBeetweenPeople.svg";
import iconSettings from "../../images/icons/styled/first/Settings.svg";
import iconCalm from "../../images/icons/styled/first/Calm.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconBuilding from "../../images/icons/styled/second/Building.svg";
import links from "../../utils/links";
import iconCreateNew from "../../images/icons/styled/first/CreateNew.svg";

export default {
    "seo": {
        "title": "Банк 131 — официальный сайт новейшего банка России",
        "description": "Банк 131 — цифровой банк, платежные решения для бизнеса: прием платежей онлайн, выплаты и расчеты с физическими лицами и самозанятыми. Лицензия ЦБ РФ, официальный партнер ФНС, интеграция по API."
    },
    "pageHeaderBlock": {
        "title": "Банк цифровой <br/>экономики",
        "text": "Прием платежей, выплаты и расчеты для российских и международных компаний."
    },

    "submitApplicationBlock": {
        "title": "Оставить заявку",
        "textSubmit": "Подключиться",
    },

    "infoCardsBlock": {
        "cardsTitle": "Решения для любых задач",
        "cards": [
            {
                "title": "Отправляйте выплаты",
                "text": "Перечисляйте деньги самозанятым и физлицам в России. Мгновенно, на российские банковские карты Visa, MasterCard, МИР, банковские счета и кошельки. Регистрируйте доход в налоговой через ФНС.",
                "icon": iconWallet,
                "description": "",
                "theme": "cardFirst",
                "fullLink": {
                    "to": "/products/payouts"
                }
            },
            {
                "title": "Принимайте платежи",
                "text": "Подключите интернет-эквайринг: принимайте оплату банковскими картами Visa, MasterCard, МИР, через платежные формы или по подписке, и получайте деньги на счёт в любом банке.",
                "icon": iconCard,
                "description": "",
                "theme": "cardSecond",
                "fullLink": {
                    "to": "/products/payments"
                }
            },
            {
                "title": "Откройте расчетный счёт ",
                "text": "Откройте расчетный счет для приема платежей и выплат. Отслеживайте операции онлайн – в интернет-банке.",
                "icon": iconBuilding,
                "description": "",
                "theme": "cardSecond",
                "fullLink": {
                    "to": "/products/account"
                }
            },
            {
                "title": "Мультирасчёты для маркетплейсов ",
                "text": "Маркетплейсам и агрегаторам для расчётов с поставщиками и партнёрами. Принимайте платежи от клиентов и распределяйте через API между всеми получателями.",
                "icon": iconSplitPayment,
                "description": "",
                "theme": "cardFirst",
                "fullLink": {
                    "to": "/products/marketplaces"
                }
            },
            {
                "title": "Мультирасчёты для C2C-сервисов",
                "text": "C2C-сервисам для расчётов между физическими лицами через платформу. Принимайте платежи от физлиц и распределяйте через API между получателями: самой платформой, и пользователями. ",
                "icon": iconShape,
                "description": "",
                "theme": "cardSecond",
                "fullLink": {
                    "to": "/products/c2c"
                }
            }
        ]
    },
    "infoCardsBlockWhyUs": {
        "cardsTitle": "Почему мы?",
        "cards": [
            {
                title: 'Развивайте бизнес',
                text: 'Используйте современные платежные технологии, чтобы  улучшить бизнес-модель, найти новые источники монетизации, оптимизировать расходы. Организуйте расчеты с исполнителями — аутсорсерами, поставщиками и курьерами — легально и удобно',
                icon: iconSettings,
                theme: 'withoutBackground'
            },
            {
                title: 'Всё для разработки',
                text: 'Современные API и SDK, подробная документация, удобная тестовая среда — всё, чтобы легко добавить платежи на вашу платформу и решать любые задачи по расчётам с людьми или компаниями и между ними.',
                icon: iconLightning,
                link: {
                    href: links.ru.api,
                    text: 'Посмотреть документацию'
                },
                theme: 'withoutBackground'
            },
            {
                title: "Всё для работы с самозанятыми",
                text: 'Мы проверяем налоговый статус физлица, проводим оплату и формируем фискальный чек — напрямую через ФНС. Всё в строгом соответствии с Налоговым кодексом и 115-ФЗ.',
                icon: iconCalm,
                theme: 'withoutBackground'
            },
            {
                title: 'Вы в надёжных руках',
                text: 'Работаем официально – с лицензией Центрального Банка РФ. Раскрываем отчетность, отвечаем перед регулятором, соответствуем международным требованиям и обеспечиваем высокий уровень безопасности по работе с карточными данными PCI DSS.',
                icon: iconShield,
                theme: 'withoutBackground'
            }
        ]
    }
}
